import pic1 from "../img/gallery/17990885_1834121430175845_5897502198857508124_n.jpg";
import pic2 from "../img/gallery/17991994_1834121616842493_2318086490994311500_n.jpg";
import pic3 from "../img/gallery/17992200_1834121553509166_3485332267004045014_n.jpg";
import pic4 from "../img/gallery/18010662_1834121536842501_3328306045682449339_n.jpg";
import pic5 from "../img/gallery/ibiza.jpg";
import pic6 from "../img/gallery/iveco.jpg";
import pic7 from "../img/gallery/honda.jpg";

export const photos = [
  {
    src: pic1,
    width: 1,
    height: 1,
    alt: "Б категорија - Голф 5",
  },
  {
    src: pic2,
    width: 1,
    height: 1,
    alt: "Училница за теоретска настава",
  },
  {
    src: pic3,
    width: 1,
    height: 1,
    alt: "А1 категорија",
  },
  {
    src: pic4,
    width: 1,
    height: 1,
    alt: "Б категорија - Опел Астра",
  },
  {
    src: pic5,
    width: 1,
    height: 1,
    alt: "Б категорија - Сеат Ибица",
  },
  {
    src: pic6,
    width: 1,
    height: 1,
    alt: "Ц и ЦЕ категорија",
  },
  {
    src: pic7,
    width: 1,
    height: 1,
    alt: "А категорија",
  },
];
