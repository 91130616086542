import React, { useState, useEffect } from "react";

import Navbar from "components/Sections/Navbar";
import Header from "components/Sections/Header";
import Categories from "components/Sections/Categories";
import AboutUs from "components/Sections/AboutUs";
import Gallery from "components/Sections/Gallery";
import Contact from "components/Sections/Contact";
import Footer from "components/Sections/Footer.js";

import ScrollToTop from "react-scroll-up";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/Modal/Modal";

const LandingPage = () => {
  const [expand, setExpand] = useState(false);

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    if (expand !== false) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [expand]);

  const openModal = (id) => {
    setExpand(id);
  };

  return (
    <>
      {!expand ? null : (
        <Modal className="modal" id={expand} onClose={() => setExpand(false)} />
      )}
      <div className={!expand ? "" : "cs-blur"}>
        <Navbar />
        <Header />
        <div className="main">
          <Categories onClick={openModal} />
          <AboutUs />
          <Gallery />
          <Contact />
          <Footer />
        </div>

        <ScrollToTop
          showUnder={500}
          style={{
            zIndex: 10,
            background: "#f5593d",
            padding: "10px",
            borderRadius: 10,
            boxShadow: "0px 12px 19px -7px rgba(0, 0, 0, 0.3)",
          }}
        >
          <FontAwesomeIcon
            icon={faChevronUp}
            className="text-white"
            size="2x"
          />
        </ScrollToTop>
      </div>
    </>
  );
};

export default LandingPage;
