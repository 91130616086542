/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import Flip from "react-reveal/Flip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Link as ScrollTo } from "react-scroll";

// core components

const Header = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("img/header1.jpg") + ")",
        }}
        className="page-header"
      >
        <div className="filter" />
        <Container>
          <Flip right cascade>
            <div className="motto text-center">
              <h1 className="cs-header">АВТО ШКОЛА ИКО</h1>
              <h3>Професионална обука за А, АМ, А1, Б, Ц и ЦЕ категорија</h3>

              <br />
            </div>
          </Flip>
          <div className="arrow bounce">
            <ScrollTo
              activeClass="active"
              to="categories"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-danger"
            >
              <FontAwesomeIcon icon={faChevronDown} size="3x" />
            </ScrollTo>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
