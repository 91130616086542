import React from "react";

import { Container, Row, Col } from "reactstrap";

import { categories } from "../../data/categories";
import CategoryCard from "../../components/Cards/CategoryCard";
import Fade from "react-reveal/Fade";

const Categories = (props) => {
  return (
    <div className="section text-center">
      <Container>
        <Fade top cascade>
          <Row id="categories">
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="title">Категории</h2>
            </Col>
          </Row>
        </Fade>
        <br />
        <br />
        <Row>
          {categories.map((category) => {
            return (
              <Col md="4" sm="6" key={category.id}>
                <Fade left>
                  <CategoryCard
                    src={category.src}
                    title={category.title}
                    onClick={() => props.onClick(category.id)}
                  />
                </Fade>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Categories;
