import React from "react";

import { Button } from "reactstrap";

const CategoryCard = (props) => {
  const src = props.src;
  const title = props.title;

  return (
    <div className="card d-flex flex-column mt-2">
      <div>
        <img
          className="img-rounded img-responsive cs-card-img pt-3"
          src={src}
          alt={title}
        />
      </div>
      <h3 className="mb-2">{title}</h3>
      <Button
        className="btn-outline-danger mb-0"
        style={{ position: "relative", bottom: 0, left: 0, right: 0 }}
        onClick={props.onClick}
      >
        ИНФО
      </Button>
    </div>
  );
};

export default CategoryCard;
