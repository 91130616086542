import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

import emailjs from "emailjs-com";
import apiKeys from "../../constants/apikeys";
import classnames from "classnames";
import Fade from "react-reveal/Fade";
import LightSpeed from "react-reveal/LightSpeed";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    mobile: false,
    message: false,
  });
  const [btnDisabled, setBtnDisabled] = useState(true);

  const MySwal = withReactContent(Swal);

  const handleInputChange = (e) => {
    let currentData = { ...formData };
    let currentErrors = { ...errors };
    let input = e.currentTarget.name;
    let value = e.currentTarget.value;

    if (value.length === 0) {
      currentErrors[input] = "Полето не може да биде празно";
    } else {
      currentErrors[input] = false;
    }

    if (input === "mobile") {
      if (!value.match("^[0-9-+ ]+$")) {
        currentErrors[input] = "Ве молиме внесете валиден телефонски број";
      } else {
        currentErrors[input] = false;
      }
    }

    if (input === "email") {
      if (!value.match("^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+.)+[A-Za-z]+$")) {
        currentErrors[input] = "Ве молиме внесете валидна е-маил адреса";
      } else {
        currentErrors[input] = false;
      }
    }

    currentData[input] = value;
    setFormData(currentData);
    setErrors(currentErrors);
  };

  useEffect(() => {
    let errorsPresent = false;
    let inputsFilled = true;
    Object.values(errors).map((error) => {
      if (error !== false) {
        errorsPresent = true;
      }
    });
    Object.values(formData).map((data) => {
      if (data.length === 0) {
        inputsFilled = false;
      }
    });
    if (errorsPresent) {
      setBtnDisabled(true);
    }
    if (!errorsPresent && inputsFilled) {
      setBtnDisabled(false);
    }
  }, [errors, formData]);

  const onSubmit = (e) => {
    e.preventDefault();

    MySwal.showLoading();
    emailjs
      .sendForm("gmail", apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID)
      .then(
        (result) => {
          MySwal.hideLoading();
          MySwal.fire({
            icon: "success",
            text: "Вашата порака е успешно испратена!",
            showConfirmButton: false,
            timer: 1500,
          });
        },
        (error) => {
          MySwal.hideLoading();
          MySwal.fire({
            icon: "error",
            text:
              "Вашата порака не е пратена! Ве молиме обидете се подоцна или јавете се на 075-311-113",
            showConfirmButton: true,
          });
        }
      );
  };

  return (
    <Form className="contact-form mt-0" onSubmit={onSubmit}>
      <Fade top cascade>
        <Row>
          <Col>
            <h4 className="text-center mt-0 mb-2">
              Имате прашање? Пратете ни порака и ние веднаш ќе ве контактираме!
            </h4>
          </Col>
        </Row>
      </Fade>
      <Row>
        <Col md="6">
          <Fade top cascade>
            <label>Име</label>
            <InputGroup
              className={classnames({
                "border-error": errors.name,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Име"
                type="text"
                name="name"
                onChange={(value) => handleInputChange(value)}
                value={formData.name}
              />
            </InputGroup>
          </Fade>
          {errors.name ? (
            <LightSpeed left cascade>
              <div>
                <p className="text-danger">{errors.name}</p>
              </div>
            </LightSpeed>
          ) : null}
        </Col>
        <Col md="6">
          <Fade top cascade>
            <label>Е-маил</label>
            <InputGroup
              className={classnames({
                "border-error": errors.email,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-email-85" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Е-маил"
                type="text"
                name="email"
                onChange={(value) => handleInputChange(value)}
                value={formData.email}
              />
            </InputGroup>
          </Fade>
          {errors.email ? (
            <LightSpeed left cascade>
              <div>
                <p className="text-danger">{errors.email}</p>
              </div>
            </LightSpeed>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Fade top cascade>
            <label>Телефон</label>
            <InputGroup
              className={classnames({
                "border-error": errors.mobile,
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-mobile" />
                </InputGroupText>
              </InputGroupAddon>

              <Input
                placeholder="Телефон за контакт"
                type="text"
                name="mobile"
                onChange={(value) => handleInputChange(value)}
                value={formData.mobile}
              />
            </InputGroup>
          </Fade>
          {errors.mobile ? (
            <LightSpeed left cascade>
              <div>
                <p className="text-danger">{errors.mobile}</p>
              </div>
            </LightSpeed>
          ) : null}
        </Col>
      </Row>
      <Fade top cascade>
        <label>Порака</label>
        <Input
          placeholder="Порака"
          type="textarea"
          rows="4"
          name="message"
          onChange={(value) => handleInputChange(value)}
          value={formData.message}
          className={classnames({
            "border-error": errors.message,
          })}
        />
      </Fade>
      {errors.message ? (
        <LightSpeed left cascade>
          <div>
            <p className="text-danger">{errors.message}</p>
          </div>
        </LightSpeed>
      ) : null}
      <Row>
        <Col className="ml-auto mr-auto" md="4">
          <Fade top cascade>
            <Button
              className={classnames("btn-fill", {
                "btn-secondary": btnDisabled,
                "btn-danger": !btnDisabled,
              })}
              disabled={btnDisabled}
              size="lg"
            >
              Испрати
            </Button>
          </Fade>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
