import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import LightSpeed from "react-reveal/LightSpeed";

import { Collapse, NavbarBrand, Navbar, Nav, Container } from "reactstrap";

import logo from "../../iko-logo.png";

const PageNavbar = () => {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    if (window.screen.width < 992) {
      setNavbarCollapse(!navbarCollapse);
      document.documentElement.classList.toggle("nav-open");
    }
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("cs-navbar-black");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", "p-0", navbarColor)} expand="lg">
      <Container>
        <LightSpeed left cascade>
          <div className="navbar-translate">
            <NavbarBrand
              data-placement="bottom"
              to="/"
              title="Logo"
              tag={Link}
              className="p-0"
            >
              <img src={logo} className="w-25" alt="Лого" />
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
        </LightSpeed>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <LightSpeed right cascade>
            <Nav className="mr-auto" navbar>
              <div className="nav-link">
                <ScrollTo
                  activeClass="active"
                  to="categories"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggleNavbarCollapse}
                  className="text-danger cs-nav-link"
                >
                  Категории
                </ScrollTo>
              </div>
              <div className="nav-link">
                <ScrollTo
                  activeClass="active"
                  to="aboutUs"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                  onClick={toggleNavbarCollapse}
                  className="text-danger cs-nav-link"
                >
                  За нас
                </ScrollTo>
              </div>
              <div className="nav-link">
                <ScrollTo
                  activeClass="active"
                  to="gallery"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggleNavbarCollapse}
                  className="text-danger cs-nav-link"
                >
                  Галерија
                </ScrollTo>
              </div>
              <div className="nav-link">
                <ScrollTo
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-40}
                  duration={500}
                  onClick={toggleNavbarCollapse}
                  className="text-danger cs-nav-link"
                >
                  Контакт
                </ScrollTo>
              </div>
              <div
                className={classnames("nav-link", {
                  "pt-0": !navbarCollapse,
                })}
              >
                {window.screen.width < 992 ? (
                  <Button className="btn-outline-danger cs-nav-link m-0">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                    <a
                      href="tel:075311113"
                      className="text-danger ml-2  cs-phone"
                    >
                      075 311 113
                    </a>
                  </Button>
                ) : null}
              </div>
            </Nav>
          </LightSpeed>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default PageNavbar;
