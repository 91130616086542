import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "reactstrap";
import { categories } from "../../data/categories";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Modal = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const current = categories.filter((category) => category.id === props.id);
    setData(current[0]);
  }, [props.id]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setLoading(false);
    }
  }, [data]);

  return (
    <div id={props.id} className={props.className}>
      <div onClick={props.onClose} className="close">
        <FontAwesomeIcon icon={faChevronLeft} size="2x" />
      </div>
      {!loading ? (
        <Container className="modal-content">
          <Row>
            <Col
              sm="6"
              className="d-flex justify-content-center align-items-center mb-5"
            >
              <h1 className="font-weight-bold text-danger text-center">
                {data.title}
              </h1>
            </Col>
            <Col
              sm="6"
              className="d-flex justify-content-center cs-card-img mb-5"
            >
              <img
                className="img-rounded img-responsive cs-card-img"
                src={data.src}
                alt={data.alt}
              />
            </Col>
          </Row>
          <Col>
            <Row>
              <VerticalTimeline>
                {data.steps.map((step, index) => {
                  return (
                    <VerticalTimelineElement
                      key={index}
                      className="vertical-timeline-element--work"
                      contentStyle={{
                        background: "rgb(245, 89, 61)",
                        color: "#fff",
                      }}
                      contentArrowStyle={{
                        borderRight: "7px solid  rgb(245, 89, 61)",
                      }}
                      date={
                        window.screen.width > 1169 ? (
                          <p className="pl-2 pr-2 mt-0">{`Чекор ${
                            index + 1
                          }`}</p>
                        ) : null
                      }
                      iconStyle={{
                        background: "rgb(245, 89, 61)",
                        color: "#fff",
                      }}
                      icon={
                        <FontAwesomeIcon
                          icon={step.icon}
                          className="text-white"
                          size="2x"
                        />
                      }
                    >
                      <h3 className="vertical-timeline-element-title">
                        {step.title}
                      </h3>
                      {/* <h4 className="vertical-timeline-element-subtitle">
                      Miami, FL
                    </h4> */}
                      <div>
                        {step.description.includes(";") ? (
                          <ul>
                            {step.description.split(";").map((li, index) => (
                              <li key={index}>
                                <p>{li}</p>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>{step.description}</p>
                        )}
                        {step.title.includes("Теоретска") ? (
                          <div>
                            <h6 className="mt-2">Корисни линкови:</h6>
                            <a
                              href="https://mvr.gov.mk/upravni-rab/27"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-white p"
                            >
                              Материјали за учење на тестови
                            </a>{" "}
                            <br></br>
                            <a
                              href="https://vozacka.weebly.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-white"
                            >
                              Вежби за совладување на материјалот
                            </a>
                            <br></br>
                            <a
                              href="https://www.avtoskolaonline.mk/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-white"
                            >
                              Тестови за вежбање
                            </a>
                            <br></br>
                          </div>
                        ) : null}
                      </div>
                    </VerticalTimelineElement>
                  );
                })}
              </VerticalTimeline>
            </Row>
          </Col>
        </Container>
      ) : null}
    </div>
  );
};

export default Modal;
