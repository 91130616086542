/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer footer-black footer-white section-dark">
      <Container>
        <Row>
          <div className="credits text-center m-auto">
            <div className="copyright">
              © {new Date().getFullYear()} Авто Школа ИКО. Сите права се
              задржани. Изработено од{" "}
              <a
                href="https://www.linkedin.com/in/igor-zdravkovski-2a1075187/"
                className="text-danger"
                target="_blank"
                rel="noopener noreferrer"
              >
                Игор Здравковски
              </a>
              .
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
