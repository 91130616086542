import React from "react";
import { Container, Row, Col } from "reactstrap";

import Slide from "react-reveal/Slide";
import GalleryPreview from "../../components/Gallery/Gallery";

const Gallery = () => {
  return (
    <div className="section landing-section">
      <Container>
        <Slide right>
          <Row>
            <Col className="ml-auto mr-auto" md="12" id="gallery">
              <h2 className="text-center mb-5">Галерија</h2>
              <GalleryPreview />
            </Col>
          </Row>
        </Slide>
      </Container>
    </div>
  );
};

export default Gallery;
