import React from "react";
import { Button, Container, Row, Col } from "reactstrap";

import GoogleMap from "components/Maps/GoogleMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Form from "../../components/Forms/Form";
import Fade from "react-reveal/Fade";

const Contact = () => {
  return (
    <div className="section section-dark" id="contact">
      <Container>
        <Fade top cascade>
          <Row>
            <Col xs="12">
              <h2 className="text-center mb-5">Контакт</h2>
            </Col>
          </Row>
        </Fade>
        <Row>
          <Col md="6">
            <GoogleMap />
            <Fade top cascade>
              <div className="d-flex align-items-center mt-3 mb-2">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="text-danger"
                  size="2x"
                />
                <h5 className="ml-3 text-white">
                  ул. „Партизанска“ бр.3, Куманово
                </h5>
              </div>
            </Fade>
            <Fade top cascade>
              <div className="d-flex align-items-center mt-3 mb-2">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="text-danger"
                  size="2x"
                />
                <h5 className="ml-3 text-white">avtoskola_iko@yahoo.com</h5>
              </div>
            </Fade>
            <Fade top cascade>
              <div className="d-flex align-items-center mt-3">
                <Button className="btn-outline-danger cs-nav-link m-0 mb-5">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  <a
                    href="tel:075311113"
                    className="text-danger ml-2  cs-phone"
                  >
                    075 311 113
                  </a>
                </Button>
                <a
                  href="https://www.facebook.com/avtoskolaiko"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-5 ml-4"
                >
                  <i className="fa fa-facebook-square fa-4x text-danger"></i>
                </a>
              </div>
            </Fade>
          </Col>
          <Col md="6">
            <Form />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
