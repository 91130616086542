import React from "react";

import CountUp from "react-countup";
import Slide from "react-reveal/Slide";
import { Container, Row, Col } from "reactstrap";

const AboutUs = () => {
  return (
    <div className="section section-dark text-center" id="aboutUs">
      <Container>
        <Slide left cascade>
          <Row>
            <Col md="6">
              <h1>
                <CountUp end={5000} duration={2.75} />+
              </h1>
              <h2>Обучени кандидати</h2>
            </Col>
            <Col md="6">
              <h1>
                <CountUp end={20} duration={2.75} />+
              </h1>
              <h2>Години искуство</h2>
            </Col>
          </Row>
        </Slide>
      </Container>
    </div>
  );
};

export default AboutUs;
