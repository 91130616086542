import AM from "../img/rsz_scooter.png";
import A from "../img/rsz_motorbike.png";
import A1 from "../img/rsz_motorbikea2.png";
import B from "../img/rsz_2golf.png";
import C from "../img/iveco-lanczos3.png";
import CE from "../img/rsz_1truckwithtrailer.png";
import {
  faMedkit,
  faChalkboardTeacher,
  faFileMedical,
  faIdCard,
  faLaptop,
  faTrafficLight,
  faDirections,
  faStarOfLife,
  faCar,
} from "@fortawesome/free-solid-svg-icons";

export const categories = [
  {
    id: 1,
    src: AM,
    title: "АМ категорија",
    steps: [
      {
        title: "Услови за АМ категорија",
        description:
          "16 години возраст;Важечка лична карта;Согласност од родители",
        icon: faStarOfLife,
      },
      {
        title: "Лекарско уверение",
        description:
          "Лекарско уверение е првиот неопходен документ за започнување на оспособување на кандидатот за возач.",
        icon: faFileMedical,
      },
      {
        title: "Теоретска настава",
        description:
          "12 часа теоретска настава распределена во 3 дена по 4 часа. Теоретската настава се изведува во професионално опремена училница која се наоѓа во склоп на просториите на Авто Школа „ИКО“.",
        icon: faChalkboardTeacher,
      },
      {
        title: "Прва помош",
        description:
          "Предавање за прва помош се изведува во просториите на Авто Школа „ИКО“ од страна на овластено медицинско лице.",
        icon: faMedkit,
      },
      {
        title: "Полагање - Тестови",
        description:
          "Полагање на теоретскиот дел се изведува во просториите на Сообраќаен испите центар „Топ Драјвер“ - Куманово.",
        icon: faLaptop,
      },
      { title: "Возачка дозвола", description: "", icon: faIdCard },
    ],
  },
  {
    id: 2,
    src: A,
    title: "А категорија",
    steps: [
      {
        title: "Услови за А категорија",
        description:
          "24 години возраст или 21 година возраст + 2 години искуство со возачка дозвола од А2 категорија;Важечка лична карта",
        icon: faStarOfLife,
      },
      {
        title: "Лекарско уверение",
        description:
          "Лекарско уверение е првиот неопходен документ за започнување на оспособување на кандидатот за возач.",
        icon: faFileMedical,
      },
      {
        title: "Теоретска настава",
        description:
          "16 часа теоретска настава распределена во 4 дена по 4 часа или 4 часа теоретска настава ако кандидатот поседува возачка дозвола од Б категорија. Теоретската настава се изведува во професионално опремена училница која се наоѓа во склоп на просториите на Авто Школа „ИКО“.",
        icon: faChalkboardTeacher,
      },
      {
        title: "Практична настава",
        description:
          "36 часа практична настава распределена во 8 дена по 2 часа. Практичната настава ја изведуваат инструктори со долгогодишно искуство кои се дел од успешниот тим на Авто Школа „ИКО“.",
        icon: faCar,
      },
      {
        title: "Полагање - Тестови",
        description:
          "Кандидатот кој поседува возачка дозвола од Б категорија е ослободен од полагање на теоретскиот дел од испитот. Полагање на теоретскиот дел се изведува во просториите на Сообраќаен испите центар „Топ Драјвер“ - Куманово.",
        icon: faLaptop,
      },
      {
        title: "Полагање - Полигон",
        description:
          "Полагање на овој дел од практичниот испит се изведува на полигонот на Авто Мото Куманово.",
        icon: faDirections,
      },
      {
        title: "Полагање - Град",
        description:
          "Полагање на овој дел од практичниот испит започнува пред просториите на Сообраќаен испите центар „Топ Драјвер“ - Куманово и е со траење од 20 до 40 минути.",
        icon: faTrafficLight,
      },
      { title: "Возачка дозвола", description: "", icon: faIdCard },
    ],
  },
  {
    id: 3,
    src: A1,
    title: "А1 категорија",
    steps: [
      {
        title: "Услови за А1 категорија",
        description:
          "16 години возраст;Важечка лична карта;Согласност од родители",
        icon: faStarOfLife,
      },
      {
        title: "Лекарско уверение",
        description:
          "Лекарско уверение е првиот неопходен документ за започнување на оспособување на кандидатот за возач.",
        icon: faFileMedical,
      },
      {
        title: "Теоретска настава",
        description:
          "16 часа теоретска настава распределена во 4 дена по 4 часа или 4 часа теоретска настава ако кандидатот поседува возачка дозвола од Б категорија. Теоретската настава се изведува во професионално опремена училница која се наоѓа во склоп на просториите на Авто Школа „ИКО“.",
        icon: faChalkboardTeacher,
      },
      {
        title: "Практична настава",
        description:
          "16 часа практична настава распределена во 8 дена по 2 часа. Практичната настава ја изведуваат инструктори со долгогодишно искуство кои се дел од успешниот тим на Авто Школа „ИКО“.",
        icon: faCar,
      },
      {
        title: "Полагање - Тестови",
        description:
          "Кандидатот кој поседува возачка дозвола од Б категорија е ослободен од полагање на теоретскиот дел од испитот. Полагање на теоретскиот дел се изведува во просториите на Сообраќаен испите центар „Топ Драјвер“ - Куманово.",
        icon: faLaptop,
      },
      {
        title: "Полагање - Полигон",
        description:
          "Полагање на овој дел од практичниот испит се изведува на полигонот на Авто Мото Куманово.",
        icon: faDirections,
      },
      {
        title: "Полагање - Град",
        description:
          "Полагање на овој дел од практичниот испит започнува пред просториите на Сообраќаен испите центар „Топ Драјвер“ - Куманово и е со траење од 20 до 40 минути.",
        icon: faTrafficLight,
      },
      { title: "Возачка дозвола", description: "", icon: faIdCard },
    ],
  },
  {
    id: 4,
    src: B,
    title: "Б категорија",
    steps: [
      {
        title: "Услови за Б категорија",
        description: "17.5 години возраст;Важечка лична карта",
        icon: faStarOfLife,
      },
      {
        title: "Лекарско уверение",
        description:
          "Лекарско уверение е првиот неопходен документ за започнување на оспособување на кандидатот за возач.",
        icon: faFileMedical,
      },
      {
        title: "Теоретска настава",
        description:
          "20 часа теоретска настава распределена во 4 дена по 4 часа или 8 часа теоретска настава ако кандидатот поседува возачка дозвола од АМ категорија. Теоретската настава се изведува во професионално опремена училница која се наоѓа во склоп на просториите на Авто Школа „ИКО“.",
        icon: faChalkboardTeacher,
      },
      {
        title: "Прва помош",
        description:
          "Предавање за прва помош се изведува во просториите на Авто Школа „ИКО“ од страна на овластено медицинско лице.",
        icon: faMedkit,
      },
      {
        title: "Практична настава",
        description:
          "36 часа практична настава распределена во 18 дена по 2 часа. Практичната настава ја изведуваат инструктори со долгогодишно искуство кои се дел од успешниот тим на Авто Школа „ИКО“.",
        icon: faCar,
      },
      {
        title: "Полагање - Тестови",
        description:
          "Полагање на теоретскиот дел се изведува во просториите на Сообраќаен испите центар „Топ Драјвер“ - Куманово.",
        icon: faLaptop,
      },
      {
        title: "Полагање - Полигон",
        description:
          "Полагање на овој дел од практичниот испит се изведува на полигонот на Авто Мото Куманово.",
        icon: faDirections,
      },
      {
        title: "Полагање - Град",
        description:
          "Полагање на овој дел од практичниот испит започнува пред просториите на Сообраќаен испите центар „Топ Драјвер“ - Куманово и е со траење од 20 до 40 минути.",
        icon: faTrafficLight,
      },
      { title: "Возачка дозвола", description: "", icon: faIdCard },
    ],
  },
  {
    id: 5,
    src: C,
    title: "Ц категорија",
    steps: [
      {
        title: "Услови за Ц категорија",
        description:
          "21 години возраст;Важечка лична карта; Возачка дозвола од Б категорија со минимум 1 година искуство",
        icon: faStarOfLife,
      },
      {
        title: "Лекарско уверение",
        description:
          "Лекарско уверение е првиот неопходен документ за започнување на оспособување на кандидатот за возач.",
        icon: faFileMedical,
      },
      {
        title: "Теоретска настава",
        description:
          "10 часа теоретска настава распределена во 3 дена (2 дена со 4 часа и 1 ден со 2 часа). Теоретската настава се изведува во професионално опремена училница која се наоѓа во склоп на просториите на Авто Школа „ИКО“.",
        icon: faChalkboardTeacher,
      },
      {
        title: "Практична настава",
        description:
          "20 часа практична настава распределена во 10 дена по 2 часа. Практичната настава ја изведуваат инструктори со долгогодишно искуство кои се дел од успешниот тим на Авто Школа „ИКО“.",
        icon: faCar,
      },
      {
        title: "Полагање - Тестови",
        description:
          "Полагање на теоретскиот дел се изведува во просториите на Сообраќаен испите центар „Топ Драјвер“ - Куманово.",
        icon: faLaptop,
      },
      {
        title: "Полагање - Полигон",
        description:
          "Полагање на овој дел од практичниот испит се изведува на полигонот на Авто Мото Куманово.",
        icon: faDirections,
      },
      {
        title: "Полагање - Град",
        description:
          "Полагање на овој дел од практичниот испит започнува од полигонот на Авто Мото Куманово и е со траење од 40 до 60 минути.",
        icon: faTrafficLight,
      },
      { title: "Возачка дозвола", description: "", icon: faIdCard },
    ],
  },
  {
    id: 6,
    src: CE,
    title: "Ц+E категорија",
    steps: [
      {
        title: "Услови за ЦЕ категорија",
        description:
          "Важечка лична карта; Возачка дозвола од Ц категорија со минимум 1 година искуство",
        icon: faStarOfLife,
      },
      {
        title: "Лекарско уверение",
        description:
          "Лекарско уверение е првиот неопходен документ за започнување на оспособување на кандидатот за возач.",
        icon: faFileMedical,
      },
      {
        title: "Теоретска настава",
        description:
          "4 часа теоретска настава. Теоретската настава се изведува во професионално опремена училница која се наоѓа во склоп на просториите на Авто Школа „ИКО“.",
        icon: faChalkboardTeacher,
      },
      {
        title: "Практична настава",
        description:
          "8 часа практична настава распределена во 4 дена по 2 часа. Практичната настава ја изведуваат инструктори со долгогодишно искуство кои се дел од успешниот тим на Авто Школа „ИКО“.",
        icon: faCar,
      },
      {
        title: "Полагање - Град",
        description:
          "Полагање на овој дел од практичниот испит започнува од полигонот на Авто Мото Куманово и е со траење од 40 до 60 минути.",
        icon: faTrafficLight,
      },
      { title: "Возачка дозвола", description: "", icon: faIdCard },
    ],
  },
];
